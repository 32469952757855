import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/article-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Test Page`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/58757b64e6058eb5209840a2baaa9aa1/cc155/fishing1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.50000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC+UlEQVQozwXB/VPTdQDA8f0hdZd3QfygFpRkui6DTrGRMmyCgDgezGOK8mAKMvbAxlibPKwTxsZobm7C4MamCd35cJ7HLxlxw8pxIgRRsjFOZl+p07vu8+71ko1F73FvepalPxIkUmmeJZ+T2HjBn8lN1lIvSG1usb65xfzSM37+9SlPVxI8XljlYewJj+IrJFIvSa4/J7WR5vUrgSz2aJHbD2I8nFtkNZnmyXKS+cW/WFheYy7+O7H4MqvraTZfvmLrteDvf/5jI/0vK6trPJ5fYGY2hsmkp7a2khvRCLLxyC1Gx6K4vNfx+EMEQhFmflliNr7MbHyF+z/+xp3pGaZ/msPt9dPv9qAzGjlaqiIv/2MOFOSzV57L2xlvsn37NmT7PtqBYl8u+fIcDn6aS96eHCrKjlJSUkxFeQkV5SoUBz+h4DM58t07+TAng/d3vMWeXVns/iCT3OwMdmVn8U7mNrIy30AWHQ8xeTNKZGKciXCIwDU/niEXjr4ejEY9HR0GbDYLZpOedu1FTIY29Jea6Gg9S0eLhs5Lp9E1VqE5fpi2c1XIJqfuYDDZ8F0bIRy9xeTt+9yYusto+HuGfNcZGPbh9gW54vJgsXVjvdyLwWyhVatDbzRyudfBheZ6TE3l2G1WZFe936HcvxeV8gt8/gATkZuMjofxBUfod3lwDg1zNRDE6/PjHHThcrvp7ulBp29Hq23FbDZQrCwk592dNDWcQTYWGhGVxfvF1431YnLqBxEMBkUgGBQDA/3CZrcJh6NXOJ39ostqEUajQVi/sQpzp1noDQbRZbWKvm8doubkSXGg4HMx7HEJWcg3SHb2exQVHcLnHWLQeQVHXzfnmxuoUldQXX2cmpoTHDlymMLCAsqOqahWV3K67hTtrRewd+roNl1k0HKWsNeOzGFpk/LkOZJKqZAsJoN0vrFeOvOVWjqlVkmaKqVUd6JIUpcopNpjCqmptlg6pz4ktdSVSvaWSqlPq5a0mi8lc0Op1NVcJuk1Sul/JYAQ5W68xyYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "fishing1",
            "title": "fishing1",
            "src": "/static/58757b64e6058eb5209840a2baaa9aa1/5a190/fishing1.png",
            "srcSet": ["/static/58757b64e6058eb5209840a2baaa9aa1/772e8/fishing1.png 200w", "/static/58757b64e6058eb5209840a2baaa9aa1/e17e5/fishing1.png 400w", "/static/58757b64e6058eb5209840a2baaa9aa1/5a190/fishing1.png 800w", "/static/58757b64e6058eb5209840a2baaa9aa1/cc155/fishing1.png 886w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The Arizona Game and Fish Department (AZGFD) has released the draw results for 2022 pronghorn and elk hunts.`}</p>
    <p>{`Customers must have an AZGFD portal account to view draw results and bonus points. Draw results no longer are made available through an automated phone system.`}</p>
    <p>{`A free AZGFD portal account (dependent account features are available) can be created by visiting accounts.azgfd.com/Account/Register. A portal account allows customers to create a secure account where they can view and manage their contact information, as well as their licenses, draw results history and bonus points in their personal “My AZGFD Dashboard” section.`}</p>
    <p>{`For questions about creating a portal account, call the department at 602-942-3000 and press “7.”`}</p>
    <p>{`By the numbers:`}</p>
    <ul>
      <li parentName="ul">{`25,136: The total number of hunt permit-tags issued.`}</li>
      <li parentName="ul">{`217,839: The total number of those who applied for hunts or bonus points.`}</li>
      <li parentName="ul">{`176,822: The total number of applications submitted.`}</li>
    </ul>
    <p>{`All hunt permit-tags were expected to be mailed by April 1.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      